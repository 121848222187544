html {
  font-size: 62.5%; }
  @media only screen and (min-width: 768px) {
    html {
      font-size: 75%; } }
  @media only screen and (min-width: 992px) {
    html {
      font-size: 87.5%; } }
  @media only screen and (min-width: 1400px) {
    html {
      font-size: 100%; } }
  @media only screen and (min-width: 1600px) {
    html {
      background-color: ghostwhite;
      max-width: 1600px;
      margin: 1rem auto; } }
  @media only screen and (max-width: 320px) {
    html {
      font-size: 50%; } }

.app {
  background-color: white; }

.admin-smallscreen {
  padding-top: 1rem; }
  .admin-smallscreen__menu {
    justify-content: center; }
    .admin-smallscreen__menu .active {
      color: #0f75bc !important;
      background-color: transparent !important; }
    .admin-smallscreen__menu__item {
      font-size: 1.6rem; }
      @media only screen and (max-width: 320px) {
        .admin-smallscreen__menu__item {
          font-size: 1.4rem; } }
      @media only screen and (min-width: 600px) {
        .admin-smallscreen__menu__item {
          font-size: 2rem; } }
      @media only screen and (min-width: 768px) {
        .admin-smallscreen__menu__item {
          font-size: 2.4rem; } }

.admin-widescreen {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: space-between; }
  .admin-widescreen__menu {
    flex-basis: 30%; }
  .admin-widescreen__main {
    flex-basis: 60%;
    flex-grow: 1; }

.contact-info-item {
  font-family: inherit;
  font-size: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center !important;
  margin-bottom: 0.5rem; }
  .contact-info-item__icon {
    flex-basis: 10%;
    font-family: inherit;
    font-size: inherit;
    text-align: left !important;
    margin: 0 !important; }
  .contact-info-item__detail {
    flex-basis: 85%;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-align: left !important; }

.delivery-info__image img {
  display: block;
  width: 100%;
  height: auto; }

.delivery-info__main {
  padding: 1rem;
  font-family: Comfortaa, Lato, sans-serif; }

.delivery-info__headline {
  font-family: inherit !important; }

.employee-card-carousel .alice-carousel__dots-item:hover,
.employee-card-carousel .alice-carousel__dots-item.__active {
  background-color: #00a651 !important; }

.employee-card-carousel .employee-card {
  border: none;
  text-align: center; }
  .employee-card-carousel .employee-card__image {
    border-radius: 50%;
    overflow: hidden;
    width: 50%;
    margin: 0 auto 1rem; }
  .employee-card-carousel .employee-card__name {
    font-size: 1.5rem;
    text-align: inherit; }
  .employee-card-carousel .employee-card__role {
    font-size: 1.1rem;
    text-align: inherit; }
  .employee-card-carousel .employee-card__bio {
    display: none; }

.employee-card {
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  color: #212121;
  font-family: Comfortaa, Lato, sans-serif;
  padding: 1rem;
  width: 100%; }
  .employee-card__image img {
    display: block;
    margin: 0 auto;
    width: 100% !important;
    height: auto; }
  .employee-card__name {
    color: #212121;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 2rem; }
  .employee-card__role {
    color: #3b3b3b;
    font-size: 1.3rem;
    margin-top: 1rem; }
  .employee-card__bio {
    font-size: 1rem;
    margin-top: 1rem;
    opacity: 0.8;
    white-space: pre-wrap; }

.employee-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important; }
  .employee-cards .employee-card {
    margin-bottom: 2rem; }

.header-menu .item {
  font-family: "Roboto Condensed", Lato, sans-serif;
  text-transform: uppercase !important; }
  @media only screen and (min-width: 768px) {
    .header-menu .item {
      font-size: 1.2rem; } }
  @media only screen and (min-width: 992px) {
    .header-menu .item {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 1400px) {
    .header-menu .item {
      font-size: 1.6rem; } }

.header-menu .active.item {
  color: #0f75bc !important;
  font-weight: bold !important;
  background-color: transparent !important; }

.header-menu .resources-menu {
  z-index: 100 !important; }

.header-menu .account-menu {
  z-index: 100 !important; }

.header {
  border-bottom: 2px solid black;
  padding: 1rem 1rem 0.5rem; }
  @media only screen and (max-width: 320px) {
    .header {
      padding-right: 0.5rem; } }
  .header__main {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header__main__logo {
      display: block; }
      .header__main__logo img {
        width: 14rem;
        height: auto; }
        @media only screen and (min-width: 768px) {
          .header__main__logo img {
            width: 18rem; } }
        @media only screen and (min-width: 992px) {
          .header__main__logo img {
            width: 20rem; } }
        @media only screen and (min-width: 1400px) {
          .header__main__logo img {
            width: 24rem; } }
        @media only screen and (max-width: 320px) {
          .header__main__logo img {
            width: 12rem; } }
    .header__main__menubar {
      display: flex;
      align-items: center;
      font-size: 2.8rem !important; }
      @media only screen and (max-width: 320px) {
        .header__main__menubar {
          font-size: 2.4rem !important; } }
      @media only screen and (min-width: 768px) {
        .header__main__menubar {
          display: none; } }
      @media only screen and (min-width: 992px) {
        .header__main__menubar {
          display: none; } }
      .header__main__menubar__help {
        color: #00a651;
        font-size: 1.6rem !important;
        margin-right: 1rem !important; }
        @media only screen and (max-width: 320px) {
          .header__main__menubar__help {
            font-size: 1.4rem !important; } }
  .header__contact {
    margin-top: 0.5rem;
    display: flex !important;
    align-items: center !important;
    padding: 0 0.5rem !important;
    justify-content: space-between !important;
    font-family: "Roboto Condensed", Lato, sans-serif !important; }
    @media only screen and (min-width: 768px) {
      .header__contact {
        justify-content: flex-start !important; } }
    .header__contact .contact-info-item {
      display: block;
      margin-bottom: 0;
      align-items: center !important;
      font-size: 0.9rem !important; }
      @media only screen and (min-width: 768px) {
        .header__contact .contact-info-item {
          font-size: 1.2rem !important;
          margin-right: 2rem; } }
      @media only screen and (min-width: 1400px) {
        .header__contact .contact-info-item {
          font-size: 1.6rem !important; } }
      @media only screen and (max-width: 320px) {
        .header__contact .contact-info-item {
          font-size: 0.8rem !important; } }
      .header__contact .contact-info-item__icon {
        color: #0f75bc;
        font-size: inherit !important; }
        @media only screen and (max-width: 320px) {
          .header__contact .contact-info-item__icon {
            font-size: 0.8rem !important; } }
      .header__contact .contact-info-item__detail {
        color: black !important;
        font-size: inherit !important; }
        @media only screen and (max-width: 320px) {
          .header__contact .contact-info-item__detail {
            font-size: 0.8rem !important; } }

.hours-item {
  color: inherit;
  display: flex;
  font-family: inherit;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }
  .hours-item__name {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    flex-basis: 50%;
    text-transform: capitalize; }
  .hours-item__times {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    flex-basis: 48%;
    text-transform: lowercase; }
    .hours-item__times--closed {
      font-weight: bold;
      text-transform: uppercase; }

.instruction-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto Condensed", Lato, sans-serif;
  margin-bottom: 1rem; }
  .instruction-item__icon {
    flex-basis: 3%;
    margin: 0 !important;
    padding: 0 !important; }
    .instruction-item__icon img {
      width: 100%;
      display: block !important;
      height: auto; }
    @media only screen and (min-width: 768px) {
      .instruction-item__icon {
        flex-basis: 2.5%; } }
  .instruction-item__heading {
    flex-basis: 18%;
    font-size: 0.9rem; }
    @media only screen and (min-width: 768px) {
      .instruction-item__heading {
        flex-basis: 16%;
        font-size: 1.2rem; } }
    @media only screen and (min-width: 992px) {
      .instruction-item__heading {
        font-size: 1.4rem; } }
    @media only screen and (max-width: 320px) {
      .instruction-item__heading {
        flex-basis: 18%;
        font-size: 0.85rem; } }
  .instruction-item__details {
    color: gray;
    flex-basis: 75%;
    font-size: 0.9rem; }
    @media only screen and (min-width: 768px) {
      .instruction-item__details {
        flex-basis: 77.5%;
        font-size: 1.2rem; } }
    @media only screen and (min-width: 992px) {
      .instruction-item__details {
        font-size: 1.4rem; } }
    @media only screen and (max-width: 320px) {
      .instruction-item__details {
        flex-basis: 73%;
        font-size: 0.85rem; } }

.location {
  font-family: inherit;
  font-size: inherit; }
  .location-address {
    font-family: inherit;
    font-size: inherit; }
    .location-address__item {
      display: block;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: 400; }
  .location-map__button {
    margin-top: 1rem !important; }

.resource-card {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  border-color: #212121 !important; }
  .resource-card__header {
    background-color: #212121 !important;
    padding-bottom: 0.6rem !important; }

.resource-card__title {
  font-family: Comfortaa, Lato, sans-serif !important;
  color: #fff !important;
  text-transform: uppercase !important; }

.resource-card__content {
  font-family: "Roboto Condensed", Lato, sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  display: flex !important;
  justify-content: space-between !important; }
  .resource-card__content .resource_url {
    display: block; }
  .resource-card__content .resource-link {
    flex-basis: 20% !important;
    color: #212121;
    text-align: right; }

.resources__intro {
  font-family: Comfortaa, Lato, sans-serif !important; }
  .resources__intro__icon {
    color: #212121 !important;
    margin-left: 0.5rem !important; }
  .resources__intro__primary {
    font-family: Comfortaa, Lato, sans-serif !important;
    font-size: 1.4rem; }
  .resources__intro__secondary {
    font-family: Comfortaa, Lato, sans-serif !important;
    font-size: 1.1rem;
    opacity: 0.8; }

.resources .resource-card:nth-child(4n + 1) .resource-card__item {
  background-color: #00a651 !important; }

.resources .resource-card:nth-child(4n + 2) .resource-card__item {
  background-color: #0f75bc !important; }

.resources .resource-card:nth-child(4n + 3) .resource-card__item {
  background-color: orangered !important; }

.resources .resource-card:nth-child(4n + 4) .resource-card__item {
  background-color: #e80c7a !important; }

.service {
  border-color: #212121 !important;
  border-width: 1px !important; }
  @media only screen and (min-width: 768px) {
    .service {
      box-shadow: 4px 4px 10px 0px rgba(0, 166, 81, 0.5) !important; } }
  .service__header {
    background-color: #00a651 !important;
    padding-bottom: 0.5rem !important; }
    @media only screen and (min-width: 768px) {
      .service__header {
        opacity: 0.8; } }
  .service__image {
    padding: 0 !important; }
    .service__image img {
      width: 100%;
      height: auto; }
  .service__main {
    border-style: none !important; }
  .service__title {
    font-family: Comfortaa, Lato, sans-serif !important;
    color: #212121 !important;
    text-transform: uppercase !important; }
    @media only screen and (max-width: calc((768px - 1px))) {
      .service__title {
        font-size: 1.3rem !important; } }
  .service__headline {
    font-family: Comfortaa, Lato, sans-serif !important;
    color: #212121; }
  .service__description {
    font-family: Comfortaa, Lato, sans-serif !important;
    color: #212121;
    opacity: 0.8;
    white-space: pre-wrap; }

.services-info {
  border-style: none !important;
  padding: 0 !important; }
  .services-info__image {
    padding: 0 !important; }
    .services-info__image img {
      display: block;
      width: 100%;
      height: auto; }
  .services-info__main {
    border-style: none !important;
    font-family: Comfortaa, Lato, sans-serif; }
  .services-info__headline {
    font-family: inherit !important; }

.services-smallscreen-menu {
  border-color: #212121 !important; }
  .services-smallscreen-menu .dropdown.icon {
    color: #212121; }
  .services-smallscreen-menu .ui.label {
    font-family: "Roboto Condensed", Lato, sans-serif !important;
    font-size: 1.1rem !important;
    color: #0f75bc !important;
    background-color: transparent;
    box-shadow: none !important; }
  .services-smallscreen-menu .menu > .item {
    font-family: "Roboto Condensed", Lato, sans-serif !important;
    font-size: 1.2rem !important;
    padding: 0 !important; }
  .services-smallscreen-menu .menu {
    max-height: 28rem !important; }

.services-smallscreen__intro__primary {
  font-family: Comfortaa, Lato, sans-serif !important;
  font-size: 1.4rem; }

.services-smallscreen__intro__secondary {
  font-family: Comfortaa, Lato, sans-serif !important;
  font-size: 1.1rem;
  opacity: 0.8; }

.services-smallscreen__main {
  padding: 0 !important; }

.services-smallscreen .service-container {
  padding: 1.5rem 1rem !important; }
  .services-smallscreen .service-container .service__header {
    padding: 1.5rem 1rem 1rem 1rem !important;
    opacity: 1 !important; }
  .services-smallscreen .service-container .service__title {
    color: #fff !important; }
  .services-smallscreen .service-container:nth-child(2n + 1) .service__header {
    background-color: #00a651 !important; }
  .services-smallscreen .service-container:nth-child(2n + 2) .service__header {
    background-color: #0f75bc !important; }

.services-widescreen-menu {
  border-color: #212121 !important;
  border-width: 1px !important;
  box-shadow: 4px 4px 10px 0px rgba(15, 117, 188, 0.5) !important; }
  .services-widescreen-menu__header {
    background-color: #0f75bc !important;
    color: white !important;
    opacity: 0.8;
    padding-bottom: 0.5rem !important; }
  .services-widescreen-menu__title {
    font-family: Comfortaa, Lato, sans-serif !important;
    text-transform: uppercase !important; }
  .services-widescreen-menu > .item {
    font-family: Comfortaa, Lato, sans-serif !important;
    font-size: 1rem !important; }
    @media only screen and (min-width: 992px) {
      .services-widescreen-menu > .item {
        font-size: 1.2rem; } }
    @media only screen and (min-width: 1400px) {
      .services-widescreen-menu > .item {
        font-size: 1.4rem; } }
  .services-widescreen-menu .active.item {
    background-color: #0f75bc !important;
    color: #fff !important; }

.side-drawer-menu {
  display: flex;
  flex-direction: column; }
  .side-drawer-menu-section {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    @media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
      .side-drawer-menu-section {
        flex-direction: row; }
        .side-drawer-menu-section .button > .icon {
          background-color: transparent !important; } }
    .side-drawer-menu-section .ui.button {
      color: #fff;
      font-size: 1.8rem;
      text-align: left;
      text-transform: uppercase;
      flex-basis: 100%; }
      @media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
        .side-drawer-menu-section .ui.button {
          font-size: 1.6rem;
          flex-basis: 50%; } }
      @media only screen and (max-width: 500px) and (orientation: landscape) {
        .side-drawer-menu-section .ui.button {
          font-size: 1.4rem; } }
  .side-drawer-menu-main .ui.button {
    background-color: #00a651; }
  .side-drawer-menu-user .ui.button {
    background-color: #0f75bc; }

.side-drawer {
  background-color: #212121;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  transform: translateX(calc((-100vw -1px)));
  transition: transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 200;
  display: flex;
  flex-direction: column; }
  .side-drawer--open {
    transform: translateX(0); }
  .side-drawer__navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem; }
    .side-drawer__navigation__intro {
      color: #00a651;
      text-align: center;
      flex-basis: 20%;
      flex-shrink: 1; }
      .side-drawer__navigation__intro h3 {
        font-family: Comfortaa, Lato, sans-serif; }
    .side-drawer__navigation .intro-icon {
      color: white; }
  .side-drawer-menu {
    padding: 4rem 2rem 2rem; }
    @media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
      .side-drawer-menu {
        padding: 1rem; } }
  .side-drawer-instructions {
    padding: 4rem 2rem;
    color: #00a651;
    text-align: center;
    font-size: 1.8rem; }
    @media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
      .side-drawer-instructions {
        font-size: 1.6rem;
        padding: 0.5rem; } }
    @media only screen and (max-width: 500px) and (orientation: landscape) {
      .side-drawer-instructions {
        font-size: 1.5rem; } }
    .side-drawer-instructions__icon {
      color: #fff; }
  .side-drawer__header {
    border-bottom: 2px solid #fff;
    padding: 1rem 1rem 0.5rem; }
    @media only screen and (max-width: 320px) {
      .side-drawer__header {
        padding-right: 0.5rem; } }
    .side-drawer__header__main {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .side-drawer__header__main__logo {
        display: block;
        width: 18rem;
        height: auto; }
        @media only screen and (min-width: 768px) {
          .side-drawer__header__main__logo {
            width: 20rem; } }
        @media only screen and (min-width: 992px) {
          .side-drawer__header__main__logo {
            width: 22rem; } }
        @media only screen and (max-width: 320px) {
          .side-drawer__header__main__logo {
            width: 12rem; } }
      .side-drawer__header__main__close {
        font-size: 2.8rem !important;
        color: #fff; }
        @media only screen and (max-width: 320px) {
          .side-drawer__header__main__close {
            font-size: 2.4rem !important; } }
        @media only screen and (min-width: 768px) {
          .side-drawer__header__main__close {
            display: none; } }
        @media only screen and (min-width: 992px) {
          .side-drawer__header__main__close {
            display: none; } }
    .side-drawer__header__contact {
      margin-top: 0.5rem;
      display: flex !important;
      align-items: center !important;
      padding: 0 0.5rem !important;
      justify-content: space-between !important;
      font-family: "Roboto Condensed", Lato, sans-serif !important; }
      .side-drawer__header__contact .contact-info-item {
        display: block;
        margin-bottom: 0;
        align-items: center !important; }
        .side-drawer__header__contact .contact-info-item__icon {
          color: #00a651;
          font-size: 1.2rem !important; }
        .side-drawer__header__contact .contact-info-item__detail {
          color: #b3b3b3 !important;
          font-size: 1.2rem !important; }

.admin-list-item {
  font-family: Comfortaa, Lato, sans-serif !important;
  margin: 1rem 0; }

.admin-list {
  display: flex;
  flex-direction: column; }
  .admin-list button {
    align-self: flex-end;
    font-size: 1.2rem !important;
    color: #212121 !important;
    background-color: transparent !important; }

.admin-page__header__title {
  font-family: Comfortaa, Lato, sans-serif !important; }

.admin-page__main {
  border: 2px solid black !important; }

.admin-section__header {
  margin: 0;
  padding: 1rem 1rem 0.5rem !important; }

.admin-section__title {
  font-family: Comfortaa, Lato, sans-serif !important;
  color: #00a651; }

.form-label {
  text-transform: uppercase !important; }

.ui.form input {
  font-family: Comfortaa, Lato, sans-serif !important; }

.ui.form textarea {
  font-family: Comfortaa, Lato, sans-serif !important; }

.ui.button {
  font-family: Comfortaa, Lato, sans-serif !important; }

.ui.buttons {
  display: flex !important;
  justify-content: space-between !important; }

.info-card__image img {
  display: block;
  width: 100%;
  height: auto; }

.info-card__main {
  padding: 1rem 0;
  font-family: Comfortaa, Lato, sans-serif; }

.info-card__headline {
  font-size: 1.2rem;
  font-family: inherit !important; }

.info-card__description {
  font-size: 1rem;
  font-family: inherit !important;
  color: gray; }

.info-card__link {
  color: #00a651;
  font-weight: bold; }

.modal-message-cta {
  color: #00a651; }

@media only screen and (min-width: 768px) {
  .about-page__intro__primary {
    font-size: 2rem !important; }
  .about-page__container {
    padding: 1rem;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .about-page__container .container-heading {
      flex-basis: 100%;
      color: #00a651;
      justify-self: center !important; }
  .about-page__location {
    padding: 0;
    flex-basis: 50%;
    background-color: transparent !important;
    align-self: flex-start !important; }
    .about-page__location .location {
      color: #212121 !important; }
      .about-page__location .location-address {
        margin-bottom: 1.5rem; }
        .about-page__location .location-address__item {
          font-size: 1.6rem !important;
          margin-bottom: 0.5rem !important; }
  .about-page .hours-contact-container {
    background: linear-gradient(135deg, #3b3b3b, #212121) !important;
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column !important;
    justify-content: flex-start !important; }
  .about-page__hours {
    flex-shrink: 1 !important;
    background-color: transparent !important;
    font-size: 1.4rem !important; }
    .about-page__hours__items {
      color: #e6e6e6; }
    .about-page__hours .hours-item {
      color: #e6e6e6; }
  .about-page__contact {
    flex-grow: 1 !important;
    background-color: transparent !important;
    color: #e6e6e6; }
    .about-page__contact .contact-info-item {
      font-size: 1.4rem !important;
      margin-top: 1rem; }
  .about-page__employees {
    flex-basis: 100%; }
    .about-page__employees .section-heading {
      font-size: 2rem !important; }
    .about-page__employees .employee-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      justify-content: flex-start !important; }
    .about-page__employees .employee-card {
      margin-right: 2%;
      flex-basis: 48%; } }

@media only screen and (min-width: 992px) {
  .about-page__intro__primary {
    font-size: 2.4rem !important; }
  .about-page__location .location-address {
    margin-bottom: 1.5rem; }
    .about-page__location .location-address__item {
      font-size: 1.8rem !important;
      margin-bottom: 1rem !important; }
  .about-page__hours {
    font-size: 1.8rem !important; }
    .about-page__hours .hours-item {
      margin-bottom: 0.5rem; }
  .about-page .contact-info-item {
    font-size: 1.8rem !important;
    margin-top: 1rem; }
  .about-page-address {
    padding-bottom: 2rem; }
    .about-page-address__item {
      font-size: 1.6rem; }
  .about-page__employees .section-heading {
    font-size: 2.4rem !important; }
  .about-page__employees .employee-card {
    flex-basis: 32%;
    margin-right: 1.3%; } }

@media only screen and (min-width: 1400px) {
  .about-page__location .container-heading {
    font-size: 3rem;
    font-size: 2rem !important; }
  .about-page__location .location-address {
    margin-bottom: 2rem; }
    .about-page__location .location-address__item {
      font-size: 2.2rem !important;
      margin-bottom: 1.5rem !important; }
  .about-page__hours {
    font-size: 2.2rem !important; }
    .about-page__hours .hours-item {
      margin-bottom: 1rem; }
  .about-page .contact-info-item {
    font-size: 2.2rem !important;
    margin-top: 1rem; }
  .about-page__employees .employee-card {
    flex-basis: 23%;
    margin-right: 2%; } }

.about-page .container-heading {
  font-family: Comfortaa, Lato, sans-serif !important;
  font-size: 2rem;
  font-weight: 800;
  color: #545454;
  margin-bottom: 2rem !important;
  text-align: left; }
  @media only screen and (min-width: 992px) {
    .about-page .container-heading {
      font-size: 2.4rem; } }

.about-page .section-heading {
  font-family: Comfortaa, Lato, sans-serif !important;
  font-size: 2rem;
  font-weight: 800;
  color: #545454;
  margin-bottom: 2rem !important;
  text-align: left; }
  @media only screen and (min-width: 992px) {
    .about-page .section-heading {
      font-size: 2.4rem; } }

@media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
  .about-page__employees {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between; }
    .about-page__employees .employee-card {
      flex-basis: 48%; }
      .about-page__employees .employee-card__name {
        font-size: 1.4rem; }
      .about-page__employees .employee-card__role {
        font-size: 1.1rem; }
      .about-page__employees .employee-card__bio {
        font-size: 0.9rem; } }

.about-page {
  font-family: Comfortaa, Lato, sans-serif; }
  .about-page__intro {
    padding: 2rem 1rem;
    background: #fff; }
    .about-page__intro__primary {
      font-family: Comfortaa, Lato, sans-serif !important;
      font-size: 1.8rem; }
    .about-page__intro__secondary {
      font-family: Comfortaa, Lato, sans-serif !important;
      font-size: 1.2rem; }
  .about-page__container {
    margin: 1.5rem 0; }
  .about-page__location {
    background-color: #0f75bc;
    padding: 2rem 1rem; }
    .about-page__location .section-heading {
      color: #e6e6e6 !important; }
    .about-page__location .location {
      font-size: 1.4rem;
      color: #fff;
      font-family: Comfortaa, Lato, sans-serif; }
      .about-page__location .location-address__item {
        margin-bottom: 0.5rem; }
      .about-page__location .location-map__button {
        color: #fff;
        background-color: #212121 !important; }
        .about-page__location .location-map__button:focus {
          color: #fff;
          background-color: #212121 !important; }
  .about-page__hours {
    background-color: #00a651;
    padding: 2rem 1rem;
    font-size: 1.2rem; }
    .about-page__hours .section-heading {
      color: #e6e6e6 !important; }
    @media only screen and (max-width: 320px) {
      .about-page__hours {
        font-size: 1.1rem; } }
    .about-page__hours__items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff; }
    .about-page__hours__icon {
      flex-basis: 10%;
      color: inherit;
      font-size: inherit;
      text-align: left !important;
      margin: 0 !important; }
    .about-page__hours .hours {
      flex-basis: 85%; }
      .about-page__hours .hours-item {
        font-family: "Roboto Condensed", Lato, sans-serif;
        color: inherit; }
  .about-page__contact {
    background-color: #212121;
    padding: 2rem 1rem; }
    .about-page__contact .contact-info-item {
      color: #cccccc;
      font-family: "Roboto Condensed", Lato, sans-serif;
      font-size: 1.2rem; }
      @media only screen and (max-width: 320px) {
        .about-page__contact .contact-info-item {
          font-size: 1.1rem; } }
  .about-page__employees {
    background-color: #fff;
    padding: 2rem 1rem 0; }
    .about-page__employees .section-heading {
      color: #212121 !important; }
  .about-page .section-heading {
    font-family: Comfortaa, Lato, sans-serif;
    font-size: 1.8rem;
    font-weight: 800;
    color: #b3b3b3;
    margin-bottom: 1.5rem; }

@media only screen and (min-width: 768px) {
  .home-page-footer {
    display: flex;
    justify-content: space-between; }
    .home-page-footer__hours {
      flex-basis: 48%;
      padding: 1.5rem 1rem;
      font-size: 1.2rem !important; }
      .home-page-footer__hours__icon {
        flex-basis: 10%; }
      .home-page-footer__hours .hours {
        flex-basis: 85%; }
        .home-page-footer__hours .hours-item {
          margin-bottom: 0.5rem; }
    .home-page-footer .copyright-abn-container {
      flex-basis: 48%;
      padding: 1.5rem 1rem; }
    .home-page-footer__copyright {
      font-size: 1.2rem !important;
      margin-bottom: 0.5rem; }
      .home-page-footer__copyright__icon {
        flex-basis: 12%; }
      .home-page-footer__copyright__detail {
        flex-basis: 85%; }
    .home-page-footer__abn {
      font-size: 1.2rem !important; }
      .home-page-footer__abn__heading {
        flex-basis: 12%; }
      .home-page-footer__abn__detail {
        flex-basis: 85%; } }

@media only screen and (min-width: 992px) {
  .home-page-footer__hours {
    font-size: 1.6rem !important; }
    .home-page-footer__hours .hours-item {
      margin-bottom: 0.5rem; }
  .home-page-footer__copyright {
    font-size: 1.6rem !important; }
  .home-page-footer__abn {
    font-size: 1.6rem !important; } }

@media only screen and (min-width: 1400px) {
  .home-page-footer {
    padding: 1.5rem 2rem; }
    .home-page-footer__hours {
      font-size: 1.8rem !important; }
      .home-page-footer__hours .hours-item {
        margin-bottom: 1rem; }
    .home-page-footer__copyright {
      font-size: 1.8rem !important; }
    .home-page-footer__abn {
      font-size: 1.8rem !important; } }

.home-page-footer {
  background-color: #212121;
  width: 100%; }
  .home-page-footer__hours {
    padding: 1rem;
    display: flex;
    font-size: 1.1rem;
    justify-content: flex-start;
    align-items: center; }
    @media only screen and (max-width: 320px) {
      .home-page-footer__hours {
        font-size: 1rem; } }
    .home-page-footer__hours__icon {
      flex-basis: 15%;
      color: #878787;
      font-size: inherit;
      text-align: left !important;
      margin: 0 !important; }
    .home-page-footer__hours .hours {
      flex-basis: 80%; }
      .home-page-footer__hours .hours-item {
        color: #b3b3b3; }
  .home-page-footer .copyright-abn-container {
    padding: 1rem; }
  .home-page-footer__copyright {
    font-family: "Roboto Condensed", Lato, sans-serif;
    font-size: 1.1rem;
    display: flex; }
    @media only screen and (max-width: 320px) {
      .home-page-footer__copyright {
        font-size: 1rem; } }
    .home-page-footer__copyright__icon {
      flex-basis: 15%;
      color: #878787;
      font-size: inherit;
      text-align: left !important;
      margin: 0 !important; }
    .home-page-footer__copyright__detail {
      flex-basis: 80%;
      color: #b3b3b3;
      font-size: inherit;
      text-align: left; }
  .home-page-footer__abn {
    font-family: "Roboto Condensed", Lato, sans-serif;
    font-size: 1.1rem;
    display: flex; }
    @media only screen and (max-width: 320px) {
      .home-page-footer__abn {
        font-size: 1rem; } }
    .home-page-footer__abn__heading {
      flex-basis: 15%;
      color: #878787;
      font-size: inherit;
      text-align: left; }
    .home-page-footer__abn__detail {
      flex-basis: 80%;
      font-size: inherit;
      color: #b3b3b3;
      text-align: left; }

@media only screen and (min-width: 768px) {
  .home-page__info-cards {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    padding: 0 1rem !important;
    margin: 1rem 0; }
  .home-page .info-card {
    flex-basis: 48%;
    border: 1px solid gray !important;
    border-radius: 4px !important; } }

@media only screen and (max-width: calc((768px - 1px))) and (orientation: landscape) {
  .home-page__info-cards {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    padding: 0 1rem !important;
    margin: 1rem 0; }
  .home-page .info-card {
    flex-basis: 48%;
    border: 1px solid gray !important;
    border-radius: 4px !important; } }

.home-page__intro {
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  font-family: Comfortaa, Lato, sans-serif !important; }
  .home-page__intro__help {
    color: #00a651; }
  .home-page__intro h3 {
    font-family: inherit;
    font-size: 2rem; }
  .home-page__intro h4 {
    font-family: inherit; }
    @media only screen and (min-width: 768px) {
      .home-page__intro h4 {
        font-size: 1.4rem; } }
  .home-page__intro h5 {
    font-family: inherit;
    color: gray; }
    @media only screen and (min-width: 768px) {
      .home-page__intro h5 {
        font-size: 1.2rem; } }
    @media only screen and (min-width: 992px) {
      .home-page__intro h5 {
        font-size: 1.3rem; } }

.home-page .info-card {
  padding: 1rem !important;
  margin-bottom: 1rem; }

.home-page__carousel {
  flex-basis: 100%;
  padding: 1rem; }
  .home-page__carousel__title {
    font-family: Comfortaa, Lato, sans-serif;
    font-size: 2rem;
    text-align: center; }
  .home-page__carousel .alice-carousel__dots {
    margin: 0.5rem; }
